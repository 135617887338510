@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Prompt:wght@500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Prompt", sans-serif;
}

.education {
  background-image: url("./assets/subtract.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-origin: border-box;
  height: 400px;
  width: 100%;
}
